import styled from 'styled-components';
import { TABLET } from '../../../../assets/variable';

export const Wrapper = styled.div`
	background: #ffffff;
	border: none;
	@media screen and (max-width: ${TABLET}) {
		margin-top: 20px;
	}
	.card {
		border: none;
		border-radius: 6px;
		.thumbnailWrapper {
			border-radius: 8px;
			.thumbnail {
				height: 257px;
				width: 100%;
				border-radius: 8px;
			}
		}

		.card-block {
			padding: 0 21px 10px 21px;
			@media screen and (max-width: ${TABLET}) {
				padding: 0 10px;
			}

			.card-title {
				margin-top: 26px;
				line-height: 140%;
				font-style: normal;
				font-weight: bold;
				font-size: 1.8rem;
				line-height: 140%;
				@media screen and (max-width: 375px) {
					font-size: 18px;
				}
				a {
					font-size: 1.4rem;
line-height: 120% !important;
					color: #4f4f4f;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.card-desc {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;
				color: #4f4f4f;
				margin-top: 10px;
			}

			.footer-desc {
				margin-top: 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;
				color: #4f4f4f;
			}
		}
	}
`;

export default Wrapper;
