import styled from 'styled-components';

export const Wrapper = styled.div`
	margin-top: 120px;
	.share {
		text-align: center;
		margin-top: 20px;
	}
	.share p {
		margin-bottom: 10px;
		font-size: 0.95rem;
	}

	.share {
		display: none;
	}

	.share ul li {
		display: inline-block;
		margin-bottom: 5px;
	}

	.share ul {
		padding-left: 0;
		margin-left: 0;
	}

	.share,
	.share a {
		color: rgba(0, 0, 0, 0.44);
		fill: rgba(0, 0, 0, 0.44);
	}

	@media (min-width: 1024px) {
		.share {
			position: fixed;
			display: block;
		}

		.share ul li {
			display: block;
		}
	}
`;

export default Wrapper;
