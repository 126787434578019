/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import DoneSVG from '../../assets/img/done.svg';

import useScrollDirection from '../../hooks/useScrollDirection';
import ImageRender from '../../helper/image';

import '../../assets/css/modalWhitePaper.scss';

const errorMessage = {
	REQUIRED_ERROR_MESSAGE: 'Kolom ini harus diisi. ',
	GENERIC_INVALID_MESSAGE: 'Informasi yang Anda masukan tidak sesuai format, silakan coba lagi.',
};

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
		borderRadius: '10px',
	},
};

const withHooksHOC = Component => {
	return props => {
		const { currentY } = useScrollDirection();

		return <Component currentY={currentY} {...props} />;
	};
};

class WhitePaper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			isSuccess: false,
			isFloatOpen: true,
			heightArticle: 0,
			validateName: '',
			validateEmail: '',
			validateCompany: '',
			validateBusiness: '',
			valueBusiness: '',
		};
	}

	timer() {
		if (document.getElementsByClassName('sib-form-message-panel--active')[0]) {
			this.setState({ isSuccess: true, modalIsOpen: false });

			clearInterval(this.intervalId);
		}
	}

	componentDidMount() {
		this.intervalId = setInterval(this.timer.bind(this), 1000);
		const script = document.createElement('script');
		script.src = 'https://sibforms.com/forms/end-form/build/main.js';
		script.async = true;

		document.body.appendChild(script);
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	render() {
		const {
			modalIsOpen,
			isSuccess,
			isFloatOpen,
			validateName,
			validateEmail,
			validateCompany,
			validateBusiness,
			valueBusiness,
		} = this.state;
		const { currentY, heightArticle } = this.props;

		const getHight = heightArticle || 1000;
		const pxShow = currentY > (80 / 100) * getHight;
		const shouldFloatOpen = isFloatOpen && pxShow;
		const isAllValid =
			validateName === 'valid' &&
			validateEmail === 'valid' &&
			validateCompany === 'valid' &&
			validateBusiness === 'valid';

		return (
			<>
				<div
					className={shouldFloatOpen ? 'floatingPaper show' : 'floatingPaper hide'}
					id="mt-float-white-paper"
				>
					<div className="imageContainer">
						{ImageRender(
							'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=200',
							'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=200',
							'https://seiturju.sirv.com/Chatbiz/pdf/cover/cover-pengalaman.png?format=webp&w=200',
							'',
							'',
							'',
							'pengalaman',
							false,
							null,
							null,
							null,
							false,
						)}
					</div>
					<div className="textContainer">
						<div className="title">
							<p>5 Tips Meningkatkan Pengalaman Pelanggan di Era Low Touch Economy</p>
							<div className="btnWrapper">
								<button
									onClick={() => {
										this.setState({
											isFloatOpen: false,
											modalIsOpen: true,
										});
									}}
									id="mtBtnFloatUnduhGratis"
								>
									Unduh Gratis
								</button>
							</div>
						</div>
						<div
							className="exit"
							onClick={() => {
								this.setState({
									isFloatOpen: false,
								});
							}}
						>
							<div>
								<span />
								<span />
							</div>
						</div>
					</div>
				</div>

				<div className={modalIsOpen ? 'modal-container show' : 'modal-container'}>
					<div className="content-modal">
						<div className="custom-modal-container">
							<div className="custom-modal-header">
								<div className="header-text">Dapatkan White Paper Gratis dari kami</div>
								<div
									className="header-exit"
									onClick={() => {
										this.setState({
											modalIsOpen: false,
										});
									}}
									id="mtBtnBlogDetailWhitePaperModalClose"
								>
									<div>
										<span />
										<span />
									</div>
								</div>
							</div>
							<div className="custom-modal-body">
								<div className="sib-form">
									<div id="sib-form-container" className="sib-form-container">
										<div id="error-message" className="sib-form-message-panel">
											<div className="sib-form-message-panel__text sib-form-message-panel__text--center">
												<svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
													<path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
												</svg>
												<span className="sib-form-message-panel__inner-text">
													Informasi Anda tidak bisa disimpan, silakan coba lagi.
												</span>
											</div>
										</div>
										<div />
										<div id="success-message" className="sib-form-message-panel">
											<div className="sib-form-message-panel__text sib-form-message-panel__text--center">
												<svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
													<path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
												</svg>
												<span className="sib-form-message-panel__inner-text">
													White Paper telah berhasil terkirim ke E-mail Anda.
												</span>
											</div>
										</div>
										<div />
										<div id="sib-container" className="sib-container--large sib-container--vertical">
											<form
												id="sib-form"
												method="POST"
												action="https://ca01da1c.sibforms.com/serve/MUIEAKfoUwuC0CTB324HbbtlN1D6Koa3va34OUk-D8AUUeBsB35f2_LhDDCA1c3fTWTbXPYdsz1KiO4_a5WWDLBm6fsFtzOKptmad7Y5UU4119X5toATw5-TT_0OBDNtJYde7yQUm_9WDAacnhFlm5yPl2wQZWYMP5pfLquRZsg5QlwU7QjjYSOIc6LuIwfkEJZ8NHeIiklAzT2d"
												data-type="subscription"
												onSubmit={e => {
													e.preventDefault();
												}}
											>
												<div>
													<div className="sib-input sib-form-block">
														<div className="form__entry entry_block">
															<div className="form__label-row ">
																<label className="entry__label" htmlFor="FULLNAME" data-required="">
																	Nama Lengkap
																</label>
																<div className="entry__field">
																	<input
																		className="input"
																		maxLength="200"
																		type="text"
																		id="FULLNAME"
																		name="FULLNAME"
																		autoComplete="off"
																		placeholder="Contoh : Ade Setiawan"
																		data-required="true"
																		required
																		onChange={e => {
																			if (e.target.value === '') {
																				this.setState({
																					validateName: errorMessage.REQUIRED_ERROR_MESSAGE,
																				});
																			} else {
																				this.setState({
																					validateName: 'valid',
																				});
																			}
																		}}
																	/>
																</div>
															</div>
															{validateName !== '' && validateName !== 'valid' ? (
																<label className="errorTxt">{validateName}</label>
															) : null}
															<label className="entry__error entry__error--primary" />
														</div>
													</div>
												</div>
												<div>
													<div className="sib-input sib-form-block">
														<div className="form__entry entry_block">
															<div className="form__label-row ">
																<label className="entry__label" htmlFor="EMAIL" data-required="">
																	E-mail Anda
																</label>
																<div className="entry__field">
																	<input
																		className="input"
																		type="email"
																		id="EMAIL"
																		name="EMAIL"
																		autoComplete="off"
																		placeholder="masukan@email.disini"
																		data-required="true"
																		required
																		onChange={e => {
																			const pattern = new RegExp(
																				/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
																			);
																			if (e.target.value === '') {
																				this.setState({
																					validateEmail: errorMessage.REQUIRED_ERROR_MESSAGE,
																				});
																			} else if (!pattern.test(e.target.value)) {
																				this.setState({
																					validateEmail: errorMessage.GENERIC_INVALID_MESSAGE,
																				});
																			} else {
																				this.setState({
																					validateEmail: 'valid',
																				});
																			}
																		}}
																	/>
																</div>
															</div>

															{validateEmail !== '' && validateEmail !== 'valid' ? (
																<label className="errorTxt">{validateEmail}</label>
															) : (
																''
															)}
															<label className="entry__error entry__error--primary" />
														</div>
													</div>
												</div>
												<div>
													<div className="sib-input sib-form-block">
														<div className="form__entry entry_block">
															<div className="form__label-row ">
																<label className="entry__label" htmlFor="COMPANY" data-required="">
																	Nama Perusahaan
																</label>
																<div className="entry__field">
																	<input
																		className="input"
																		maxLength="200"
																		type="text"
																		id="COMPANY"
																		name="COMPANY"
																		autoComplete="off"
																		placeholder="Tuliskan nama perusahaan / institusi anda"
																		data-required="true"
																		required
																		onChange={e => {
																			if (e.target.value === '') {
																				this.setState({
																					validateCompany: errorMessage.REQUIRED_ERROR_MESSAGE,
																				});
																			} else {
																				this.setState({
																					validateCompany: 'valid',
																				});
																			}
																		}}
																	/>
																</div>
															</div>
															{validateCompany !== '' && validateCompany !== 'valid' ? (
																<label className="errorTxt">{validateCompany}</label>
															) : (
																''
															)}
															<label className="entry__error entry__error--primary" />
														</div>
													</div>
												</div>
												<div>
													<div className="sib-select sib-form-block" data-required="true">
														<div className="form__entry entry_block">
															<div className="form__label-row ">
																<label className="entry__label" htmlFor="SECTOR" data-required="">
																	Bidang Usaha
																</label>
																<div className="entry__field">
																	<select
																		className={valueBusiness !== '' ? 'input' : 'input placeholder'}
																		id="SECTOR"
																		name="SECTOR"
																		data-required="true"
																		onChange={e => {
																			this.setState({
																				valueBusiness: e.target.value,
																			});
																			if (e.target.value === '') {
																				this.setState({
																					validateBusiness: errorMessage.REQUIRED_ERROR_MESSAGE,
																				});
																			} else {
																				this.setState({
																					validateBusiness: 'valid',
																				});
																			}
																		}}
																	>
																		<option
																			value=""
																			id="SECTOR__disabled-option"
																			className="placeholder"
																			selected
																			disabled
																			hidden
																		>
																			Contoh : Agraris
																		</option>
																		​
																		<option className="sib-menu__item" value="1">
																			Teknologi
																		</option>
																		<option className="sib-menu__item" value="2">
																			Otomotif
																		</option>
																		<option className="sib-menu__item" value="3">
																			Fashion
																		</option>
																		<option className="sib-menu__item" value="4">
																			Kuliner
																		</option>
																		<option className="sib-menu__item" value="5">
																			Kecantikan
																		</option>
																		<option className="sib-menu__item" value="6">
																			Kerajinan Tangan
																		</option>
																		<option className="sib-menu__item" value="7">
																			Tour &amp; Travel
																		</option>
																		<option className="sib-menu__item" value="8">
																			Agribisnis
																		</option>
																		<option className="sib-menu__item" value="9">
																			Pendidikan
																		</option>
																		<option className="sib-menu__item" value="10">
																			Kelontong
																		</option>
																		<option className="sib-menu__item" value="11">
																			Lainnya
																		</option>
																	</select>
																</div>
															</div>
															{validateBusiness !== '' && validateBusiness !== 'valid' ? (
																<label className="errorTxt">{validateBusiness}</label>
															) : (
																''
															)}
															<label className="entry__error entry__error--primary" />
														</div>
													</div>
												</div>
												<div>
													<div className="sib-form-block">
														<div className="submit-description">
															Pastikan alamat email anda aktif, tautan akan kami kirim ke E-mail anda.
														</div>
														<button
															className="sib-form-block__button sib-form-block__button-with-loader"
															form="sib-form"
															type="submit"
															disabled={!isAllValid}
															id="mtBtnBlogDetailWhitePaperModal"
														>
															<svg
																className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
																viewBox="0 0 512 512"
															>
																<path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
															</svg>
															Kirimkan Saya White Paper
														</button>
													</div>
												</div>
												<input type="text" name="email_address_check" value="" className="input--hidden" />
												<input type="hidden" name="locale" value="en" />
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal
					isOpen={isSuccess}
					style={customStyles}
					contentLabel="Modal White Paper"
					overlayClassName={
						!isSuccess ? 'OverlayWhitePaperModal' : 'OverlayWhitePaperModal successOverlay'
					}
					// onAfterOpen={afterOpenModal}
					// onRequestClose={closeModal}
				>
					<OutsideClickHandler
						onOutsideClick={() => {
							this.setState({
								isSuccess: false,
								modalIsOpen: false,
							});
						}}
					>
						<div className="custom-modal-container">
							<div className="custom-modal-header-success">
								<div className="left">
									<img src={DoneSVG} alt="doneSVG" />
									<div className="header-text">Cek Email Anda</div>
								</div>
								<div className="right">
									<div
										className="header-exit"
										onClick={() => {
											this.setState({
												isSuccess: false,
												modalIsOpen: false,
											});
										}}
										id="mtBtnBlogDetailWhitePaperModalCheckEmailClose"
									>
										<div>
											<span />
											<span />
										</div>
									</div>
								</div>
							</div>
							<div className="custom-modal-body-success">
								<p className="txt-success">
									Terima kasih link sudah terkirim. Anda juga dapat melihat White Paper lainnya dari website
									kami.
								</p>
								<div className="button-container">
									<button
										className="button-primary"
										onClick={() => {
											window.location.replace('/white-paper');
										}}
										id="mtBtnBlogDetailWhitePaperModalCheckEmail"
									>
										Lihat White Paper Lainnya
									</button>
									{/* <button className="button-link" type="button">
                    Tidak, Lanjutkan Membaca
                  </button> */}
								</div>
							</div>
						</div>
					</OutsideClickHandler>
				</Modal>
			</>
		);
	}
}

WhitePaper.propTypes = {
	currentY: PropTypes.number.isRequired,
	heightArticle: PropTypes.number.isRequired,
};

export default withHooksHOC(WhitePaper);
