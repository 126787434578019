import styled from 'styled-components';

export const Wrapper = styled.div`
	background: #f9fbff;

	padding-bottom: 68px;
	position: relative;
	.title {
		padding-top: 68px;
		padding-bottom: 54px;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 140%;
		text-align: center;
		color: #4f4f4f;
		text-align: center;
	}
`;

export default Wrapper;
