/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { convertToId } from '../../../helper/time';
import ShareButton from './ShareButton';
import { Wrapper } from './style';

const BlogPost = ({ post, refArticle, currentLocation, tableOfContentText, rText }) => {
	const { title, cover, author } = post;

	const renderTags = () => {
		if (!post.tags || post.tags.length <= 0) return <></>;

		return post.tags.map(val => (
			<li>
				<Link to={`/blog/tag/${val.slug}`}>{val.title}</Link>
			</li>
		));
	};

	return (
		<Wrapper>
			<div className="container">
				<div className="row">
					<div className="col-md-2 col-xs-12">
						<ShareButton currentLocation={currentLocation} />
					</div>
					<div className="col-md-8 col-md-offset-2 col-xs-12 contentWrapper">
						<div className="mainheading blogpost">
							<h1 className="blogpost__title">{title || ''}</h1>

							<div className="author">
								<div className="author__image">
									<Img className="img" alt={title || ''} fluid={author ? author.cover.fluid : null} />
								</div>
								<div className="author__content">
									<a
										className="author__content--name"
										href={author ? author.link : ''}
										target="_blank"
										rel="noopener noreferrer"
									>
										{author ? author.name : ''}
									</a>

									<span className="author__content--date"> {convertToId(post.createdAt)}</span>
								</div>
							</div>

							<Img
								className="blogpost__cover img-fluid"
								alt={title || ''}
								fluid={cover ? cover.fluid : null}
							/>
							{tableOfContentText}
							<div className="blogpost__article" ref={refArticle}>
								{rText}
							</div>
							<div className="blogpost__tags">
								<ul>{renderTags()}</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

BlogPost.propTypes = {
	post: PropTypes.instanceOf(Object),
	refArticle: PropTypes.any,
	currentLocation: PropTypes.string,
	tableOfContentText: PropTypes.any,
	rText: PropTypes.any,
};

export default BlogPost;
