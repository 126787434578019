/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import ArticlePreview from './ArticlePreview';
import { Wrapper } from './style';

const OtherArticle = ({ otherPosts }) => {
	const renderOthers = () => {
		if (otherPosts.length <= 0) return <></>;
		return otherPosts.map(({ node }) => {
			return (
				<div className="col-md-4">
					<ArticlePreview article={node} />
				</div>
			);
		});
	};

	return (
		<>
			<Wrapper>
				<div className="title">Artikel Lainnya</div>
				<div className="container">
					<div className="row listrecent listrelated">{renderOthers()}</div>
				</div>
			</Wrapper>
		</>
	);
};

OtherArticle.propTypes = {
	otherPosts: PropTypes.instanceOf(Array),
};

export default OtherArticle;
