/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Layout from '../components/layout';
import RichText from '../components/richText';
import TableOfContent from '../components/richText/tableOfContents';
import { convertToDefaultFormat } from '../helper/time';
import ModalWhitePaper from '../components/modal/whitepaper';
import BlogPost from '../components/_blog/BlogPost';
import OtherArticle from '../components/_blog/OtherArticle';
import useDetectIntl from '../hooks/useDetectIntl';
import Page404 from '../pages/404';

const BlogPostTemplate = ({ data, location }) => {
	const refArticle = useRef(null);
	const { isIntl } = useDetectIntl();
	const [heightArticle, setHeightArticle] = useState(0);
	const post = get(data, 'contentfulBlogPost');
	const otherPosts = get(data, 'others.edges');
	// const siteTitle = get(data, 'site.siteMetadata.title');
	const currentLocation = location.href;

	if (isIntl) {
		return <Page404 />;
	}

	useEffect(() => {
		const getHeight = () => {
			const { current } = refArticle; // `ref` will never be falsy
			if (current && current.clientHeight) {
				setHeightArticle(current.clientHeight);
			}
		};
		getHeight();
	}, [refArticle.current]);

	const { seo, title, cover, author, content, updatedAt } = post;
	const rText = content ? RichText(content) : '';
	const htmlRText = ReactDOMServer.renderToString(rText);
	const rTableOfContent = content ? TableOfContent(content) : '';

	const stricturedData = () => {
		return JSON.stringify({
			'@context': 'http://schema.org',
			'@type': 'BlogPosting',
			name: seo ? seo.title : '',
			author: {
				'@type': 'Person',
				name: author ? author.name : '',
			},
			datePublished: convertToDefaultFormat(post.createdAt),
			image: cover ? `https:${cover.fluid.src}` : null,
			articleSection: seo && seo.description ? seo.description.description : '',
			articleBody: htmlRText,
			url: currentLocation,
			publisher: {
				'@type': 'Organization',
				name: 'chatbiz.id',
				url: 'https://chatbiz.id/',
				logo: 'https://seiturju.sirv.com/Chatbiz/logo-footer.svg',
			},
			headline: seo ? seo.title : '',
			dateModified: updatedAt,
			mainEntityOfPage: {
				'@type': 'WebPage',
			},
		});
	};
	return (
		<Layout location={location}>
			<Helmet>
				<title>{seo ? seo.title : ''}</title>
				<meta name="description" content={seo && seo.description ? seo.description.description : ''} />

				<meta property="og:title" content={seo ? seo.title : ''} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={currentLocation} />
				<meta
					property="og:description"
					content={seo && seo.description ? seo.description.description : ''}
				/>
				<meta property="og:image" content={cover ? `https:${cover.fluid.src}` : null} />

				<script type="application/ld+json">{stricturedData()}</script>
				<html lang="id" />

				<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />

				<script src="https://sibforms.com/forms/end-form/build/main.js" />
				{isIntl ? (
					<meta name="robots" content="noindex, nofollow" />
				) : (
					<meta name="robots" content="index, follow" />
				)}
			</Helmet>

			<BlogPost
				tableOfContentText={rTableOfContent}
				post={post}
				refArticle={refArticle}
				currentLocation={currentLocation}
				rText={rText}
			/>

			<div className="hideshare" />
			<OtherArticle otherPosts={otherPosts} />
			<ModalWhitePaper heightArticle={heightArticle} />
		</Layout>
	);
};

BlogPostTemplate.propTypes = {
	data: PropTypes.instanceOf(Object),
	location: PropTypes.object.isRequired,
};

export default props => (
	<Location>{locationProps => <BlogPostTemplate {...locationProps} {...props} />}</Location>
);

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		others: allContentfulBlogPost(limit: 3, filter: { slug: { ne: $slug } }) {
			edges {
				node {
					title
					slug
					createdAt
					description
					cover {
						fluid(maxWidth: 400, background: "rgb:000000") {
							...GatsbyContentfulFluid_withWebp
						}
					}
					author {
						name
						cover {
							fluid(maxWidth: 50, background: "rgb:000000") {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
				}
			}
		}
		contentfulBlogPost(slug: { eq: $slug }) {
			title
			createdAt
			updatedAt
			cover {
				fluid(maxWidth: 800, background: "rgb:000000") {
					...GatsbyContentfulFluid_withWebp
				}
			}
			content {
				json
			}
			author {
				name
				description
				link
				cover {
					fluid(maxWidth: 300, background: "rgb:000000") {
						...GatsbyContentfulFluid_withWebp
					}
				}
			}
			category {
				title
				slug
			}
			tags {
				title
				slug
			}
			seo {
				title
				description {
					description
				}
			}
		}
	}
`;
