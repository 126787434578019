import styled from 'styled-components';

export const WrapperContainer = styled.div`
	background: #f9fbff;

	box-shadow: 0px 5px 10px rgba(93, 150, 205, 0.15);
	border-radius: 8px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin-top: 30px;
	padding-top: 2px;
`;

export const ButtonWrapper = styled.div`
	background: #ffffff;

	box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
	border-radius: 8px;
	padding: 0.938rem 1.188rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.title-table-of-content {
		font-family: Merriweather;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 150%;
		color: #4f4f4f;
	}
	button {
		width: 24px;
		height: 24px;
		background: #ffffff;
		box-shadow: 0px 5px 10px rgba(93, 150, 205, 0.15);
		border: none;
		border-radius: 50%;
		&:focus {
			outline: none;
		}
		&.isClose {
			picture,
			img {
				transform: rotate(180deg);
			}
		}

		picture,
		img {
			width: 12px;
		}
	}
`;

export const Wrapper = styled.div`
	&.table-of-content {
		max-height: 30vh;

		width: 100%;
		height: 100%;
		overflow-y: scroll;
		box-sizing: content-box;
		padding: 1.25rem 0;
		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		@keyframes fadeOut {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		&.close {
			display: none;
			-webkit-animation: fadeOut 1s;
			animation: fadeOut 1s;
		}
		&.open {
			display: block;
			-webkit-animation: fadeIn 1s;
			animation: fadeIn 1s;
		}
		button {
			font-family: Merriweather, 'Times New Roman', Serif;
			font-size: 1.15rem;
			font-style: normal;
			font-weight: normal;
			line-height: 175%;
			border: none;
			background-color: transparent;
			display: flex;

			color: #0664be;
			text-align: left;
			&:active {
				color: rgb(255, 143, 28);
				.dotted {
					background: rgb(255, 143, 28);
				}
			}
			&:hover {
				color: rgb(0, 157, 219);
				.dotted {
					background: rgb(0, 157, 219);
				}
			}
			&:focus {
				outline: none;
			}
			b {
				font-weight: normal;
				font-family: Merriweather, 'Times New Roman', Serif;
				font-size: 1.15rem;
				font-style: normal;
				font-weight: normal;
				line-height: 175%;
				color: #0664be;
				&:active {
					color: rgb(255, 143, 28);
				}
				&:hover {
					color: rgb(0, 157, 219);
				}
			}
		}

		.dotted {
			width: 8px;
			height: 8px;

			border-radius: 50%;
			background: #0664be;
			margin-right: 0.438rem;
			margin-top: 0.8rem;
			flex-shrink: 0;
		}

		ol {
			list-style: none;
			margin: 0;
			padding: 0;

			li.indent-1 {
				margin-left: 1.188rem;
				margin-top: 0.625rem;
			}
			li.indent-2 {
				margin-left: 3.5rem;
			}
			li.indent-3 {
				margin-left: 4.438rem;
			}
			li.indent-4 {
				margin-left: 6.063rem;
			}
		}
		/* ol {
			counter-reset: item;
		}

		ol > li {
			counter-increment: item;
		}

		ol ol {
			counter-reset: item;
		}

		ol ol > li {
			display: block;
		}

		ol ol > li:before {
			content: counters(item, '.') '. ';
			margin-left: -20px;
		} */
	}
`;

export default Wrapper;
