/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import animateScrollTo from 'animated-scroll-to';

import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { convToId } from '../../../helper/text';
import { SVGRender } from '../../../helper/image';
import { Wrapper, ButtonWrapper, WrapperContainer } from './style';

const headingTypes = [BLOCKS.HEADING_2, BLOCKS.OL_LIST, BLOCKS.HEADING_3];
const headingTypesNested = [BLOCKS.HEADING_2, BLOCKS.HEADING_3];

const ContentfulRichText = text => {
	const scrollAnimated = doc => {
		animateScrollTo(doc, {
			verticalOffset: -80,
		});
	};

	const nestedRichTextOptions = {
		renderNode: {
			[BLOCKS.HEADING_2]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-1">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-2">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-3">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
		},
	};

	const richTextOptions = {
		renderNode: {
			[BLOCKS.HEADING_2]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-1">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-2">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				const idVal = convToId(node.content[0].value);
				return (
					<li className="indent-3">
						<button
							type="button"
							onClick={() => {
								scrollAnimated(document.querySelector(`#${idVal}`));
							}}
						>
							<div className="dotted" />
							{children}
						</button>
					</li>
				);
			},
			[BLOCKS.OL_LIST]: node => {
				const filteredHeading = [];
				node.content.forEach(item => {
					const fil = item.content.filter(v => {
						return headingTypesNested.includes(v.nodeType);
					});

					if (fil) {
						filteredHeading.push({
							odeType: 'list-item',
							content: fil,
						});
					}
				});

				const docOl = {
					nodeType: 'ordered-list',
					content: filteredHeading,
				};

				const renderTxt = documentToReactComponents(docOl, nestedRichTextOptions);
				return <li>{renderTxt}</li>;
			},
		},
	};

	const headings = text.json.content.filter(item => headingTypes.includes(item.nodeType));
	// console.log('---headings', headings);
	const doc = {
		nodeType: 'document',
		content: headings,
	};

	const [isOpen, setIsOpen] = useState(false);
	if (headings.length < 3) return <></>;
	return (
		<WrapperContainer>
			<ButtonWrapper>
				<div className="title-table-of-content">Daftar Isi</div>
				<button
					type="button"
					className={isOpen ? '' : 'isClose'}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				>
					{SVGRender('https://seiturju.sirv.com/Chatbiz/ico-expand-less.svg', 'ico-expand')}
				</button>
			</ButtonWrapper>
			<Wrapper className={isOpen ? 'table-of-content open' : 'table-of-content close'}>
				<ol>{documentToReactComponents(doc, richTextOptions)}</ol>
			</Wrapper>
		</WrapperContainer>
	);
};

export default ContentfulRichText;
