/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import { convToId } from '../../helper/text';

const richTextOptions = {
	renderNode: {
		[BLOCKS.HEADING_2]: (node, children) => {
			const idVal = convToId(node.content[0].value);
			return <h2 id={idVal}>{children}</h2>;
		},
		[BLOCKS.HEADING_3]: (node, children) => {
			const idVal = convToId(node.content[0].value);
			return <h3 id={idVal}>{children}</h3>;
		},

		[BLOCKS.EMBEDDED_ASSET]: node => {
			if (!get(node, 'data.target.fields.file')) return <></>;

			const { file } = node.data.target.fields;

			const mimeType = file['en-US'].contentType;
			const mimeGroup = mimeType.split('/')[0];
			let title = '';
			const linkTitle = node.data.target.fields.title['en-US'].split('Link:');
			const getSize = node.data.target.fields.title['en-US'].split('Size:');

			if (linkTitle) {
				title = linkTitle[0]
					.replace(new RegExp('Size:small', 'g'), '')
					.replace(new RegExp('Size:medium', 'g'), '')
					.replace(new RegExp('Size:maximum', 'g'), '')
					.trim();
			} else {
				title = node.data.target.fields.title['en-US'];
			}

			let widthSize = '100%';
			if (getSize.length > 1) {
				if (getSize[1].includes('medium')) {
					widthSize = '60%';
				} else if (getSize[1].includes('small')) {
					widthSize = '40%';
				}
			}

			if (mimeGroup === 'image') {
				if (linkTitle[1]) {
					return (
						<a href={linkTitle[1]} target="__blank">
							<img
								alt={title}
								src={`${file['en-US'].url}?fm=webp&w=800`}
								style={{
									width: widthSize,
									marginTop: '1.5rem',
									marginBottom: '1.5rem',
								}}
							/>
						</a>
					);
				}
				return (
					<img
						alt={title}
						src={`${file['en-US'].url}?fm=webp&w=800`}
						style={{
							width: widthSize,
							marginTop: '1.5rem',
							marginBottom: '1.5rem',
						}}
					/>
				);
			}
			return (
				<span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
			);

			// switch (mimeGroup) {
			// 	case 'image':
			// 		return (
			// 			<img
			// 				alt="img"
			// 				src={`${file['en-US'].url}?fm=webp&w=400`}
			// 				style={{
			// 					width: '40%',
			// 				}}
			// 			/>
			// 		);

			// 	default:
			// 		return (
			// 			<span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
			// 		);
			// }
		},
	},
};

const ContentfulRichText = function(text) {
	return documentToReactComponents(text.json, richTextOptions);
};

export default ContentfulRichText;
