import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #fff;
	.contentWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: 1300px) {
			padding: 0 3rem;
		}
	}

	.blogpost {
		max-width: 800px;
		margin-top: 150px;
		padding-left: 10px;
		padding-right: 10px;

		@media (max-width: 1069px) {
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: 768px) {
			margin-top: 20px;
		}
		&__title {
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 140%;
			color: #4f4f4f;
			@media (max-width: 375px) {
				font-size: 28px;
			}
		}
		&__article {
			font-family: Merriweather, 'Times New Roman', Serif;
			margin-top: 35px;
			color: #4f4f4f;
			padding-left: 0px;

			line-height: 175%;
			i {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-style: italic;
			}
			p {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-size: 1.15rem;
				font-style: normal;
				font-weight: normal;
				line-height: 175%;
				margin-bottom: 2rem;
				@media screen and (max-width: 375px) {
					font-size: 1em;
				}
			}

			b {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-weight: bold;
			}

			a {
				font-family: Merriweather, 'Times New Roman', Serif;
				&:active {
					color: rgb(255, 143, 28);
				}
				&:hover {
					color: rgb(0, 157, 219);
				}
			}

			h1,
			h2,
			h3 {
				padding-top: 2.5rem;
			}

			h1,
			h2,
			h3 {
				font-family: Nunito, 'Times New Roman', Serif;

				line-height: 175%;
			}
			h1 {
				font-size: 2.25rem;
			}
			h2 {
				font-size: 1.8rem;
			}
			h3 {
				font-size: 1.4rem;
			}
			h4,
			h5,
			h6 {
				font-family: Nunito, 'Times New Roman', Serif;
			}
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}

			ol,
			ul {
				margin-left: 17px;
				li {
					margin-left: 2.15rem;
					padding: 0 1.15rem;
					hr {
						border-color: transparent;
						margin-bottom: 0rem;
						margin-top: 0rem;
						&:not(:first-child) {
							margin-top: 1rem;
						}
					}
				}
				p {
					margin-bottom: 0.15rem;
				}
			}

			hr {
				margin-top: 3rem;
				margin-bottom: 3rem;
			}
		}
		&__tags {
			margin-top: 80px;
			ul {
				list-style: none;
				padding-left: 0;
				margin: 0 0 3rem;
				li {
					display: inline-block;
					font-size: 0.9rem;
					margin-top: 1.5rem;
					a {
						margin-right: 0.5rem;
						border-radius: 3px;
						padding: 8px 15px;
						background: rgba(0, 0, 0, 0.05);
						color: rgba(0, 0, 0, 0.6);
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.author {
		margin-top: 19px;
		margin-bottom: 35px;
		display: flex;
		&__image {
			.img {
				width: 75px;
				height: 75px;
				border-radius: 50%;
			}
		}
		&__content {
			margin-left: 32px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			&--name {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1.15rem;
				line-height: 32px;
				color: #4f4f4f;
				@media (max-width: 375px) {
					font-size: 16px;
				}
				&:hover {
					text-decoration: none;
				}
			}
			&--date {
				margin-top: 2px;
				font-family: Merriweather, 'Times New Roman', Serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1.15rem;
				line-height: 32px;
				color: #4f4f4f;
			}
		}
	}
`;

export default Wrapper;
